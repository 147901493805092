


    const API_URLL = 'https://i-need.co.il:3001/'; // Base API URL
    //const API_URLL = 'http://localhost:3001/'; // Base API URL

    //const API_URL = 'http://localhost:3001/api'; // Base API URL
    const API_URL = 'https://i-need.co.il:3001/api'; // Base API URL

//
//
export { API_URL,API_URLL };
